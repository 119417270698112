import React from 'react';
import { graphql } from 'gatsby';
import { useIntl } from 'gatsby-plugin-react-intl';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';

import { Container, Button, Markdown } from '../components/theme';
import { Layout } from '../components/layout';
import Seo from '../components/Seo';

import BubbleBg from '../assets/bubble.png';

function SingleFaq({ data }) {
  const { faqTitle, faqContent, faqCategory } = data.faq;

  const intl = useIntl();
  return (
    <Layout>
      <Box
        sx={(theme) => ({
          padding: '15px 0 55px',
          textAlign: 'center',
          [theme.breakpoints.up('sm')]: {
            padding: '35px 0 30px',
          },
        })}
      >
        <Container narrow>
          <Box
            sx={(theme) => ({
              backgroundImage: `url(${BubbleBg})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: '100% 100%',
              padding: '48px 16px 65px',
              transform: 'rotate(-1deg)',
              [theme.breakpoints.up('sm')]: {
                margin: '0 100px',
              },
            })}
          >
            <Box
              component="h1"
              sx={(theme) => ({
                color: theme.palette.text.secondary,
                fontFamily: 'Flicker',
                fontSize: theme.typography.pxToRem(26),
                fontWeight: 'normal',
                lineHeight: '1.4em',
                margin: '0',
                [theme.breakpoints.up('md')]: {
                  fontSize: theme.typography.pxToRem(32),
                  lineHeight: '1.5em',
                },
              })}
            >
              {faqTitle}
            </Box>
          </Box>
        </Container>
      </Box>
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.background.paper,
          color: theme.palette.text.secondary,
          padding: '1px 0 80px',
          [theme.breakpoints.up('md')]: {
            padding: '10px 0 100px',
          },
        })}
      >
        <Container narrow>
          <Markdown source={faqContent.data.Content} />
          <Box
            sx={(theme) => ({
              margin: '45px 0 0',
              textAlign: 'center',
              [theme.breakpoints.up('sm')]: {
                margin: '60px 0 0',
              },
              '& a': {
                color: theme.palette.text.primary,
              },
            })}
          >
            {/* ToDo: Move "back" to a more/back-component */}
            <Button
              color="secondary"
              size="small"
              variant="contained"
              to={`/faq#${faqCategory.name.replace(/[^A-Z0-9]/gi, '_')}`}
            >
              {intl.formatMessage({ id: 'backToFaq' })}
            </Button>
          </Box>
        </Container>
      </Box>
    </Layout>
  );
}

SingleFaq.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.object.isRequired,
};

export default SingleFaq;

export const query = graphql`
  query GetSingleFaq($Slug: String) {
    faq: strapiFaq(Slug: { eq: $Slug }) {
      faqTitle: Title
      faqContent: Content {
        data {
          Content
        }
      }
      faqCategory: category {
        name
      }
    }
  }
`;

export function Head({ data }) {
  return <Seo title={data.faq.faqTitle} />;
}
